import { MEMBERSHIP_STATUS } from '@smu-chile/pkg-unimarc-hooks/shared/constants'

// react query general config
export const MINUTES_OUTDATED_INFO = 1000 * 60 * 5
export const REACT_QUERY_GENERAL = {
  retry: 3,
  staleTime: MINUTES_OUTDATED_INFO,
  refetchOnWindowFocus: false
}

// to get images from contenful
export const EMPTY_ORANGE_IMG = {
  0: 'biRXfIqeSvhltdz6EiwEs'
}

export const OFFER_YOU_CAROUSEL = {
  0: '5U7lxDAk71etf1LfNOa1GA'
}

export const LOGIN_MODAL_IMAGE = {
  0: '4iejdhvwSq9nkDDaDvkdH7'
}

export const VALIDATE_SC_MODAL_IMAGE = {
  0: '39mfaeyBygzQvDgVq4h7bR'
}

export const SOMETHING_IS_WRONG_MODAL_IMAGE = {
  0: '2TUvQviWSgMU01kL1uMMqW'
}

export const LOGO_HEADER_UNIMARC = {
  0: '4Vye5opR0v9K7l2WoChprO'
}

export const CONFIRM_ADDRESS_MODAL_IMAGE = {
  0: '5l90l0SrXwdtFFAdmHlH4H'
}

export const SHIPMENT_ERROR_IMAGE = {
  0: '3N2CLul7cy8oqPqyYxCxEV'
}

export const SHORT_DAYS = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb']
export const SHORT_MONTH = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic'
]

export const HEADER_TITLE = ['Mi carro', 'Mi envío']

export const CAMPAING_APP_CHIPS_UNIMARC = 'bW8BAmjWGBidoJjqdWWp7'

export const FEATURES_FLAGS_IDENTIFIERS = {
  testFlag: 'My_Test_Flag'
}

export const CO_COOKIES = ['co_catoken', 'co_idtoken', 'co_userid']

export const MEMBERSHIP_BOX = {
  [MEMBERSHIP_STATUS.DEFAULT]: 'Ly3EsJtUWRJj6JAbiXVMs',
  [MEMBERSHIP_STATUS.EXPIRED]: '444zT3i391QbPoFKNu5qic',
  [MEMBERSHIP_STATUS.INACTIVE]: '444zT3i391QbPoFKNu5qic'
}
